import { MdRefresh as RefreshIcon } from "react-icons/md"

export default function Reload() {
  const doReload = () => {
    document.location.reload()
  }

  return (
    <>
      <button
        onClick={doReload}
        title="点击刷新页面"
        class="focus:outline-none rounded-md p-0.5 text-gray-600 hover:bg-gray-400 dark:text-gray-400 dark:hover:bg-gray-600"
      >
        <RefreshIcon class="w-6 h-6" />
      </button>
    </>
  )
}
